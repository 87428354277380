import { useQuery } from '@tanstack/react-query'
import type { Address } from 'viem'
import { TransactionListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'
import type {
  TransactionListItem as TxItem,
  RecentTransactionsListItem as RecentTxItem,
} from 'generated/reactQueryClient'
import { getTimeInSecondsToFinalizeTransaction } from 'utils/getTimeInSecondsToFinalizeTransaction'
import { captureError } from 'utils/sentry'

export type UpdatedTransactionType = TxItem & { timeLeftToFinalize?: number }

type UseUpdatedTransactionsProps = {
  transactions: RecentTxItem[] | TxItem[]
}

type TransactionType = RecentTxItem | TxItem

export const useUpdatedTransactions = ({ transactions = [] }: UseUpdatedTransactionsProps) =>
  useQuery({
    enabled: transactions.length > 0,
    queryKey: ['updatedTransactions', transactions],
    queryFn: async () =>
      Promise.all(
        transactions.map(async (transaction: TransactionType) => {
          const isInChallengePeriod =
            transaction.exitStatus === TxItemExitStatus.in_challenge_period

          if (!isInChallengePeriod) {
            return transaction
          }

          try {
            const timeLeftToFinalize = await getTimeInSecondsToFinalizeTransaction({
              hash: transaction.l2TxHash as Address,
              l1Timestamp: transaction.l1Timestamp,
            })

            return {
              ...transaction,
              ...(timeLeftToFinalize && { timeLeftToFinalize }),
            }
          } catch (error) {
            captureError(error as Error)
            return transaction
          }
        }),
      ),
  })
