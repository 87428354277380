import type { FlexProps } from '@chakra-ui/react'
import { Button, Flex, IconButton, Text, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { useIsTabletWidth } from 'components/Breakpoint'
import { COLOR_MODE } from 'constants/common'

type PaginationProps = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  isDisabled?: boolean
} & FlexProps

export const Pagination = ({
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  isDisabled = false,
  ...props
}: PaginationProps) => {
  const { colorMode } = useColorMode()
  const { t } = useTranslation(['common'])
  const { isTabletWidth } = useIsTabletWidth()

  const isPreviousPageDisabled = !hasPreviousPage || isDisabled
  const isNextPageDisabled = !hasNextPage || isDisabled

  const isLightMode = colorMode === COLOR_MODE.LIGHT

  const COMMON_ICON_BUTTON_PROPS = {
    fontSize: 20,
    variant: 'icon',
    border: 'none',
    color: isLightMode ? 'grey01' : 'white',
    _disabled: { color: isLightMode ? 'grey05' : 'grey03' },
  }

  return (
    <Flex alignItems="center" minW="fit-content" gap={{ base: 2.5, md: 1.5 }} {...props}>
      {isTabletWidth ? (
        <>
          <IconButton
            {...COMMON_ICON_BUTTON_PROPS}
            onClick={() => {
              onPreviousPage()
            }}
            icon={<RiArrowLeftSLine />}
            aria-label={t('AriaLabel.PreviousPage')}
            isDisabled={isPreviousPageDisabled}
            display={{ base: 'inline-flex', md: 'none' }}
          />

          <IconButton
            {...COMMON_ICON_BUTTON_PROPS}
            onClick={() => {
              onNextPage()
            }}
            aria-label={t('AriaLabel.NextPage')}
            icon={<RiArrowRightSLine />}
            isDisabled={isNextPageDisabled}
            display={{ base: 'inline-flex', md: 'none' }}
          />
        </>
      ) : (
        <>
          <Button
            {...COMMON_ICON_BUTTON_PROPS}
            onClick={() => {
              onPreviousPage()
            }}
            leftIcon={<RiArrowLeftSLine />}
            aria-label={t('AriaLabel.PreviousPage')}
            isDisabled={isPreviousPageDisabled}
            display={{ base: 'none', md: 'inline-flex' }}
          >
            <Text variant="caption2regular" color="inherit">
              {t('Pagination.PreviousPage')}
            </Text>
          </Button>
          <Button
            {...COMMON_ICON_BUTTON_PROPS}
            onClick={() => {
              onNextPage()
            }}
            aria-label={t('AriaLabel.NextPage')}
            rightIcon={<RiArrowRightSLine />}
            isDisabled={isNextPageDisabled}
            display={{ base: 'none', md: 'inline-flex' }}
          >
            <Text variant="caption2regular" color="inherit">
              {t('Pagination.NextPage')}
            </Text>
          </Button>
        </>
      )}
    </Flex>
  )
}
