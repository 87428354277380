import { Flex } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { RiAlarmLine } from 'react-icons/ri'
import { RecentTransactionsListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'

type TransactionButtonTimerProps = {
  timeLeftToFinalize: number
  exitStatus: TxItemExitStatus
  onTransactionFinalized: () => Promise<void>
}

export const TransactionButtonTimer = React.memo(
  ({ timeLeftToFinalize, exitStatus, onTransactionFinalized }: TransactionButtonTimerProps) => {
    const [timeLeft, setTimeLeft] = React.useState<number>(() => timeLeftToFinalize)
    const timeLeftRef = React.useRef<number>(timeLeft)

    React.useEffect(() => {
      timeLeftRef.current = timeLeft
    }, [timeLeft])

    React.useEffect(() => {
      if (!timeLeftToFinalize || exitStatus !== TxItemExitStatus.in_challenge_period) {
        return () => {}
      }

      const UPDATE_TIME_INTERVAL_IN_SECONDS = timeLeftRef.current >= 60 ? 60 : 1

      const interval = setInterval(() => {
        const newTime = Math.max(timeLeftRef.current - UPDATE_TIME_INTERVAL_IN_SECONDS, 0)
        setTimeLeft(newTime)

        if (newTime <= UPDATE_TIME_INTERVAL_IN_SECONDS) {
          clearInterval(interval)
          void onTransactionFinalized()
        }
      }, UPDATE_TIME_INTERVAL_IN_SECONDS * 1_000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeftToFinalize, exitStatus, onTransactionFinalized])

    return exitStatus === TxItemExitStatus.in_challenge_period && !!timeLeft ? (
      <Flex gap={1} alignItems="center">
        {timeLeft < 60 ? '>' : ''}
        {dayjs.duration(timeLeft < 60 ? 60 : timeLeft, 'seconds').format('HH:mm')}
        <RiAlarmLine color="inherit" size={16} />
      </Flex>
    ) : null
  },
)

TransactionButtonTimer.displayName = 'Timer'
