import type { LinkProps } from '@chakra-ui/next-js'
import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { RiExternalLinkLine } from 'react-icons/ri'
import { Link } from 'components/Link'

type ExternalLinkProps = {
  label: string
  iconPosition?: 'left' | 'right'
} & LinkProps

export const ExternalLink = ({ label, iconPosition = 'left', ...props }: ExternalLinkProps) => (
  <Link target="_blank" rel="noopener noreferrer" wordBreak="break-word" isTruncated {...props}>
    <Flex
      minW={30}
      minH={5}
      gap={1.5}
      alignItems="center"
      flexDirection={iconPosition === 'left' ? 'row' : 'row-reverse'}
    >
      <RiExternalLinkLine style={{ flexShrink: 0 }} />
      <Text isTruncated _hover={{ color: 'inherit' }} color="inherit" variant="text2medium">
        {label}
      </Text>
    </Flex>
  </Link>
)
