import dayjs from 'dayjs'
import type { Address } from 'viem'
import { getWithdrawals } from 'viem/op-stack'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { captureError } from 'utils/sentry'

type GetTimeInSecondsToFinalizeTransaction = {
  hash: Address
  l1Timestamp: number | null
}

export const getTimeInSecondsToFinalizeTransaction = async ({
  hash,
  l1Timestamp,
}: GetTimeInSecondsToFinalizeTransaction) => {
  const { DEFAULT_L1_CONTRACT_ADDRESSES, OptimismPortalProxy } = getSmartContracts()

  if (!l1Timestamp) {
    return null
  }

  try {
    const receipt = await publicClientL2.getTransactionReceipt({
      hash,
    })

    const [message] = getWithdrawals(receipt)

    const timeLeftToFinalize = await publicClientL1.getTimeToFinalize({
      withdrawalHash: message.withdrawalHash,
      l2OutputOracleAddress: DEFAULT_L1_CONTRACT_ADDRESSES!.L2OutputOracle as Address,
      portalAddress: OptimismPortalProxy!.address,
    })

    const finalizeTimestamp = dayjs.unix(l1Timestamp).add(timeLeftToFinalize.period, 'seconds')

    return finalizeTimestamp.diff(dayjs(), 'seconds')
  } catch (error) {
    if (error instanceof Error) {
      captureError(error as Error)
    }

    return null
  }
}
