export const AlphanetPectraL2StandardBridge = {
  abi: [
    {
      inputs: [],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      stateMutability: 'payable',
      type: 'receive',
    },
    {
      inputs: [],
      name: 'MESSENGER',
      outputs: [
        {
          internalType: 'contract CrossDomainMessenger',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'OTHER_BRIDGE',
      outputs: [
        {
          internalType: 'contract StandardBridge',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'accessController',
      outputs: [
        {
          internalType: 'contract AccessControlPausable',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_localToken',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_remoteToken',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: '_minGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'bridgeERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_localToken',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_remoteToken',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'uint32',
          name: '_minGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'bridgeERC20To',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint32',
          name: '_minGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'bridgeETH',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          internalType: 'uint32',
          name: '_minGasLimit',
          type: 'uint32',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'bridgeETHTo',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'deposits',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      name: 'erc20ThrottleWithdrawals',
      outputs: [
        {
          internalType: 'uint208',
          name: 'maxAmountPerPeriod',
          type: 'uint208',
        },
        {
          internalType: 'uint48',
          name: 'periodLength',
          type: 'uint48',
        },
        {
          internalType: 'uint256',
          name: 'maxAmountTotal',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_localToken',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_remoteToken',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'finalizeBridgeERC20',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_from',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_to',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_amount',
          type: 'uint256',
        },
        {
          internalType: 'bytes',
          name: '_extraData',
          type: 'bytes',
        },
      ],
      name: 'finalizeBridgeETH',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'getErc20ThrottleWithdrawalsCredits',
      outputs: [
        {
          internalType: 'uint256',
          name: 'availableCredits',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'contract StandardBridge',
          name: '_otherBridge',
          type: 'address',
        },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'messenger',
      outputs: [
        {
          internalType: 'contract CrossDomainMessenger',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'otherBridge',
      outputs: [
        {
          internalType: 'contract StandardBridge',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint208',
          name: 'maxAmountPerPeriod',
          type: 'uint208',
        },
        {
          internalType: 'uint256',
          name: 'maxAmountTotal',
          type: 'uint256',
        },
      ],
      name: 'setErc20ThrottleWithdrawalsMaxAmount',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
        {
          internalType: 'uint48',
          name: '_periodLength',
          type: 'uint48',
        },
      ],
      name: 'setErc20ThrottleWithdrawalsPeriodLength',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'version',
      outputs: [
        {
          internalType: 'string',
          name: '',
          type: 'string',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'localToken',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'remoteToken',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: 'extraData',
          type: 'bytes',
        },
      ],
      name: 'ERC20BridgeFinalized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'localToken',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'remoteToken',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: 'extraData',
          type: 'bytes',
        },
      ],
      name: 'ERC20BridgeInitiated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: 'extraData',
          type: 'bytes',
        },
      ],
      name: 'ETHBridgeFinalized',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'from',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'to',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'bytes',
          name: 'extraData',
          type: 'bytes',
        },
      ],
      name: 'ETHBridgeInitiated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint64',
          name: 'version',
          type: 'uint64',
        },
      ],
      name: 'Initialized',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'target',
          type: 'address',
        },
      ],
      name: 'AddressEmptyCode',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'AddressInsufficientBalance',
      type: 'error',
    },
    {
      inputs: [],
      name: 'FailedInnerCall',
      type: 'error',
    },
    {
      inputs: [],
      name: 'InvalidInitialization',
      type: 'error',
    },
    {
      inputs: [],
      name: 'NotInitializing',
      type: 'error',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'token',
          type: 'address',
        },
      ],
      name: 'SafeERC20FailedOperation',
      type: 'error',
    },
  ],
} as const
