export const Betanet2L1StandardBridge = {
  abi: [
    {
      type: 'constructor',
      inputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'receive',
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'MESSENGER',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract CrossDomainMessenger',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'OTHER_BRIDGE',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract StandardBridge',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'accessController',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract AccessControlPausable',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'bridgeERC20',
      inputs: [
        {
          name: '_localToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_remoteToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_amount',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_minGasLimit',
          type: 'uint32',
          internalType: 'uint32',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'bridgeERC20To',
      inputs: [
        {
          name: '_localToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_remoteToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_to',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_amount',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_minGasLimit',
          type: 'uint32',
          internalType: 'uint32',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'bridgeETH',
      inputs: [
        {
          name: '_minGasLimit',
          type: 'uint32',
          internalType: 'uint32',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'bridgeETHTo',
      inputs: [
        {
          name: '_to',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_minGasLimit',
          type: 'uint32',
          internalType: 'uint32',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'deposits',
      inputs: [
        {
          name: '',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '',
          type: 'address',
          internalType: 'address',
        },
      ],
      outputs: [
        {
          name: '',
          type: 'uint256',
          internalType: 'uint256',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'finalizeBridgeERC20',
      inputs: [
        {
          name: '_localToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_remoteToken',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_from',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_to',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_amount',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'finalizeBridgeETH',
      inputs: [
        {
          name: '_from',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_to',
          type: 'address',
          internalType: 'address',
        },
        {
          name: '_amount',
          type: 'uint256',
          internalType: 'uint256',
        },
        {
          name: '_extraData',
          type: 'bytes',
          internalType: 'bytes',
        },
      ],
      outputs: [],
      stateMutability: 'payable',
    },
    {
      type: 'function',
      name: 'initialize',
      inputs: [
        {
          name: '_messenger',
          type: 'address',
          internalType: 'contract CrossDomainMessenger',
        },
        {
          name: '_superchainConfig',
          type: 'address',
          internalType: 'contract SuperchainConfig',
        },
      ],
      outputs: [],
      stateMutability: 'nonpayable',
    },
    {
      type: 'function',
      name: 'messenger',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract CrossDomainMessenger',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'otherBridge',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract StandardBridge',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'paused',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'bool',
          internalType: 'bool',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'superchainConfig',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'address',
          internalType: 'contract SuperchainConfig',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'function',
      name: 'version',
      inputs: [],
      outputs: [
        {
          name: '',
          type: 'string',
          internalType: 'string',
        },
      ],
      stateMutability: 'view',
    },
    {
      type: 'event',
      name: 'ERC20BridgeFinalized',
      inputs: [
        {
          name: 'localToken',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'remoteToken',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: false,
          internalType: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
          indexed: false,
          internalType: 'uint256',
        },
        {
          name: 'extraData',
          type: 'bytes',
          indexed: false,
          internalType: 'bytes',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'ERC20BridgeInitiated',
      inputs: [
        {
          name: 'localToken',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'remoteToken',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: false,
          internalType: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
          indexed: false,
          internalType: 'uint256',
        },
        {
          name: 'extraData',
          type: 'bytes',
          indexed: false,
          internalType: 'bytes',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'ETHBridgeFinalized',
      inputs: [
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
          indexed: false,
          internalType: 'uint256',
        },
        {
          name: 'extraData',
          type: 'bytes',
          indexed: false,
          internalType: 'bytes',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'ETHBridgeInitiated',
      inputs: [
        {
          name: 'from',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'to',
          type: 'address',
          indexed: true,
          internalType: 'address',
        },
        {
          name: 'amount',
          type: 'uint256',
          indexed: false,
          internalType: 'uint256',
        },
        {
          name: 'extraData',
          type: 'bytes',
          indexed: false,
          internalType: 'bytes',
        },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'Initialized',
      inputs: [
        {
          name: 'version',
          type: 'uint64',
          indexed: false,
          internalType: 'uint64',
        },
      ],
      anonymous: false,
    },
    {
      type: 'error',
      name: 'AddressEmptyCode',
      inputs: [
        {
          name: 'target',
          type: 'address',
          internalType: 'address',
        },
      ],
    },
    {
      type: 'error',
      name: 'AddressInsufficientBalance',
      inputs: [
        {
          name: 'account',
          type: 'address',
          internalType: 'address',
        },
      ],
    },
    {
      type: 'error',
      name: 'FailedInnerCall',
      inputs: [],
    },
    {
      type: 'error',
      name: 'InvalidInitialization',
      inputs: [],
    },
    {
      type: 'error',
      name: 'NotInitializing',
      inputs: [],
    },
    {
      type: 'error',
      name: 'SafeERC20FailedOperation',
      inputs: [
        {
          name: 'token',
          type: 'address',
          internalType: 'address',
        },
      ],
    },
  ],
} as const
